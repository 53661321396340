@import '../../_styles/imports';

#template-riders {
}

.riders-list {
  position: relative;

  padding: 0;

  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  li {
    flex: 0 0 20%;
    width: 20%;
    margin-bottom: 4rem;
    padding: 0 1em;

    @include above('netbook') {
      &:nth-child(9n-3) {
        margin-left: 1em;
      }

      &:nth-child(9n) {
        margin-right: 1em;
      }
    }

    @include between('tablet', 'netbook') {
      flex-basis: 25%;

      &:nth-child(7n-2) {
        margin-left: 1em;
      }

      &:nth-child(7n) {
        margin-right: 1em;
      }
    }

    @include between('sm', 'tablet') {
      flex-basis: 33.333%;

      &:nth-child(5n-1) {
        margin-left: 1em;
      }

      &:nth-child(5n) {
        margin-right: 1em;
      }
    }

    @include below('sm') {
      flex-basis: 50%;
    }
  }

  hr {
    flex: 0 0 100%;
  }

  .rider {
    cursor: pointer;
    transition: margin-bottom 0.3s ease-in-out;

    h3 {
      margin-bottom: 0;
      padding-top: 3.33em;

      color: $primary;
      background: url('/images/assets/rider.svg') no-repeat center top;
      background-size: 3.33em;

      text-align: center;
      font-size: 1.5em;
      font-family: $headings-font-family;
      font-weight: bold;
    }

    small {
      position: relative;

      display: block;

      text-align: center;
    }

    &:hover {
      h3 {
        color: $secondary;
      }
    }

    &.rider__detailOpen {
      margin-bottom: 15em;

      @include below('lg') {
        margin-bottom: 24em;
      }

      @include below('phone') {
        margin-bottom: 36em;
      }

      h3 {
        color: $secondary;
      }

      small {
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;

          margin-top: 1rem;
          margin-left: -1rem;

          border: 1rem solid transparent;
          border-top-width: 0;
          border-bottom-color: $primary;
        }

        &:after {
          z-index: 50;
          border-bottom-color: white;
          transform: translate3d(0, 1.414px, 0);
        }
      }
    }
  }
}
