$primary: #112b56;
$secondary: #f297b9;
$dark: #1f1e1d;

$grid-breakpoints: (
  xs: 0,
  watch: 320px,
  phone: 480px,
  sm: 576px,
  phablet: 640px,
  tablet: 768px,
  md: 768.01px,
  netbook: 896px,
  lg: 992px,
  laptop: 1024px,
  desktop: 1200px,
  xl: 1200.01px,
  width: 1440px,
  full: 1600px,
  hd: 1920px,
  tv: 2560px,
  threek: 2880px,
  fourk: 3840px,
);

$headings-font-family: 'DIN Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-sans-serif: 'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$headings-font-weight: 700;
