@import '../../_styles/imports';

dl.datalist {
  margin: 0;

  display: flex;
  flex-wrap: wrap;

  dt,
  dd {
    margin: 0;
  }

  &.jumbo {
    justify-content: space-around;
    > span {
      padding: 0 1em;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      dt,
      dd {
        display: block;
      }

      dd {
        position: relative;
        order: -1;

        color: $primary;

        font-size: 6em;
        font-family: $headings-font-family;
        line-height: 1;

        sup,
        sub {
          position: absolute;
          padding-left: 0.125em;
          color: rgba($dark, 0.16);
          font-size: 0.25em;
          vertical-align: top;
          line-height: 3;
        }
      }

      dt {
        text-transform: uppercase;
        font-weight: normal;
      }
    }
  }

  &.icons {
    > span {
      margin-right: 1.4em;

      dt,
      dd {
        display: inline-block;
      }

      dt:not(:empty) + dd {
        margin-left: 0.7em;
      }

      dt:empty {
        + dd {
          font-size: 1.5em;
        }
      }
    }
  }
}
