@import '../../_styles/imports';

#template-home {
  @include below('phablet') {
    #stats {
      .datalist {
        flex-wrap: nowrap;
        justify-content: flex-start;
        > span {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      @for $items from 1 to 12 {
        @at-root {
          @keyframes slider-#{$items} {
            @for $item from 0 to $items {
              #{($item) * (100% / $items)} {
                margin-left: -100% * $item;
              }
              #{(($item + 1) * (100% / $items)) - 5%} {
                margin-left: -100% * $item;
              }
            }
            100% {
              margin-left: 0;
            }
          }
        }

        &[data-items='#{$items}'] {
          .datalist {
            width: 100%;
            > span {
              &:first-child {
                position: relative;
                animation: slider-#{$items} $items * 2s ease-in-out 0s infinite forwards;
              }
            }
          }
        }
      }
    }
  }

  #news-ticker {
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    color: white;
    background-color: $secondary;

    display: flex;

    .content {
      flex: 1 0 50%;
      margin: 0 1em;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    font-size: 1.25em;

    > a {
      color: inherit;
      font-weight: bold;
      text-align: right;
    }

    @include below('tablet') {
      flex-wrap: wrap;

      > strong,
      > a {
        order: 2;
        flex: 0 0 50%;
      }

      .content {
        max-height: 3 * 1.5em;
        flex: 0 0 100%;
        margin: 0;

        white-space: normal;
      }
    }

    @include below('phone') {
      > strong,
      > a {
        flex-basis: 100%;

        order: 0;
        order: initial;

        text-align: center;
      }
    }
  }

  #rides {
    h2 {
      margin-bottom: 1em;

      font-size: 4em;
      text-transform: uppercase;
    }

    ol {
      display: flex;

      padding: 0;
      padding-right: 4em;

      overflow: hidden;

      list-style: none;

      > li {
        flex: 0 0 33.333%;
        padding-right: 2em;

        display: flex;

        border: 0;

        @include below('laptop') {
          flex-basis: 50%;

          &:nth-child(4) {
            display: none;
          }
        }

        @include below('tablet') {
          flex-basis: 100%;

          &:nth-child(3) {
            display: none;
          }
        }

        &.more {
          align-self: center;
          font-size: 2em;
          flex-basis: auto;
          order: 2;
        }

        > .ride {
          flex: 0 0 100%;
        }
      }
    }
  }

  #standings-container {
    display: flex;
    flex-wrap: wrap-reverse;

    background-color: $dark;
  }

  #standings-gallery {
    position: relative;

    flex: 1 0 50%;

    display: flex;

    @include below('netbook') {
      min-height: 50vw;
    }

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    > .carousel {
      flex: 0 0 100%;
      width: 100%;

      .carousel-inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .carousel-item {
        width: 100%;
        height: 100%;
      }
    }
  }

  #standings {
    flex: 0 0 auto;
    padding: 2em;

    color: white;
    background-color: inherit;

    h2 {
      margin-bottom: 1em;

      color: white;

      font-size: 3em;
      text-transform: uppercase;

      span {
        margin: 0 0.25em;
        cursor: pointer;

        svg {
          margin-right: 0.25em;
          transform: scale(0.67);
        }

        &.active {
          color: $secondary;
        }
      }
    }

    ol {
      position: relative;

      margin: 0;
      padding: 2em 0 0 6em;

      list-style: none;

      &:before {
        content: '';
        position: absolute;
        top: 2em;
        bottom: 0;
        left: 2em;

        width: 0.25em;

        background-color: $secondary;
      }

      > li {
        position: relative;

        + li {
          margin-top: 4em;
        }

        &:before {
          content: attr(data-place);
          position: absolute;
          left: -3em;
          top: -0.5em;

          width: 2em;
          height: 2em;

          display: flex;
          justify-content: center;
          align-items: center;

          color: $dark;
          background-color: $secondary;
          border-radius: 1em;

          font-size: 2em;
          font-weight: bold;
        }
      }

      ul {
        padding: 0;

        font-size: 1.5em;
        font-weight: bold;

        list-style: none;
      }
    }
  }
}
