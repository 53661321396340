@import '../../_styles/imports';

$collapse: 'netbook';

.template-text {
  position: relative;

  display: flex;

  @include below($collapse) {
    flex-wrap: wrap;
  }

  & + & {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -1.5em;
      margin-top: -4em;

      height: 4em;
      width: 3em;

      background: url(/images/assets/rider.svg) no-repeat center center;
      background-size: contain;
    }
    @include below($collapse) {
      margin-top: 4em;
    }
    @include above($collapse) {
      &:before {
        left: 37.5%;
        margin-top: -2em;
      }
    }
  }

  > article {
    position: relative;
    z-index: 5;

    flex: 0 1 48rem;
    max-width: 48rem;

    font-size: 1.25em;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    ul,
    ol,
    figure {
      &:not(:last-child) {
        margin-bottom: 1em;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:not(:first-child) {
        margin-top: 1em;
      }
      &:first-child {
        margin-top: 0;
      }
    }

    h2 {
      font-size: 2em;
    }
    h3 {
      font-size: 1.5em;
    }

    blockquote {
      padding-top: 0.5em;
      padding-bottom: 0.5em;

      color: $primary;
      background-color: white;

      font-family: $headings-font-family;
      font-size: 3rem;
      text-align: right;
      line-height: 1;

      &:before,
      &:after {
        position: absolute;
        left: 0;
        right: 0;

        height: 2em;

        background: linear-gradient(rgba(white, 0), white);
      }
      &:before {
        bottom: 100%;
      }
      &:after {
        top: 100%;
        transform: rotate(180deg);
      }

      @include above($collapse) {
        position: absolute;
        left: 100%;

        width: 16rem;
        margin-top: -32%;
        padding-top: 1em;
        padding-bottom: 1em;

        font-size: 4rem;
        hyphens: auto;

        &:before,
        &:after {
          content: '';
        }
      }

      @include below($collapse) {
        padding-left: 1em;
      }
    }

    figure {
      margin: 2em 0;

      img {
        max-width: 100%;
        display: block;
      }

      figcaption {
        margin-top: 0.5em;
        font-size: 0.875rem;
        text-transform: uppercase;
      }
    }
  }

  > aside {
    flex: 0 0 16rem;
    width: 16rem;

    display: flex;
    flex-direction: column;

    text-align: right;

    @include below($collapse) {
      width: 100%;
      flex-basis: 100%;

      display: contents;
    }

    > *:not(hr) {
      position: relative;
      z-index: 3;

      margin: 0;

      background-color: white;

      &:not(:first-child) {
        padding-top: 2rem;
      }

      &:not(:last-child) {
        padding-bottom: 2rem;
      }

      &:before,
      &:after {
        position: absolute;
        left: 0;
        right: 0;

        height: 2rem;

        background: linear-gradient(rgba(white, 0), white);
      }
      &:before {
        bottom: 100%;
      }
      &:after {
        top: 100%;
        transform: rotate(180deg);
      }

      @include above($collapse) {
        &:before,
        &:after {
          content: '';
        }
      }

      @include below($collapse) {
        position: static;

        order: -1;

        text-align: left;
      }
    }

    > hr {
      margin-top: auto;

      @include below($collapse) {
        ~ * {
          order: 2;

          text-align: right;
        }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      @include above($collapse) {
        padding-left: 1em;
      }
      @include below($collapse) {
        padding-bottom: 0 !important;
      }
    }

    ul,
    ol {
      padding: 0;

      list-style: none;

      li {
        margin-top: 1em;
      }

      h5 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    #related {
      @include above($collapse) {
        position: sticky;
        position: -webkit-sticky;
        bottom: 1em;
        z-index: 2;

        background-color: white;
      }
    }
  }
}
