@import '../../_styles/imports';

$media-gap: 1em;

.mediagrid {
  padding: 0;

  column-count: 3;
  column-width: 16em;
  column-gap: $media-gap;

  list-style: none;

  .media-item {
    margin-bottom: $media-gap;

    break-inside: avoid;
    cursor: pointer;

    figure {
      width: 100%;

      img {
        max-width: 100%;
        display: block;
      }
    }
  }
}
