@import '../../_styles/imports';

#nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 450;

  min-height: 4em;

  color: white;

  a {
    color: inherit;
    text-decoration: none;
  }
}

#nav-main,
#nav-sub {
  margin: 0;

  list-style: none;
}

#nav-main {
  display: flex;
  justify-content: space-between;

  background-color: rgba(black, 0.8);
  backdrop-filter: blur(7px);

  font-family: $headings-font-family;
  font-size: 1.5em;
  text-transform: uppercase;

  a {
    position: relative;

    padding: 0.833em;
    display: block;
    line-height: 1;

    &.active,
    &:hover {
      color: $secondary;
    }

    &.active {
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;

        margin-left: -0.625rem;

        border: 0.625rem solid transparent;
        border-bottom-color: white;
      }

      &.has-subNav {
        &:after {
          border-bottom-color: $secondary;
        }
      }
    }
  }
}

#nav-sub {
  display: flex;
  justify-content: center;

  color: white;
  background-color: $secondary;

  font-size: 1.5em;
  text-transform: lowercase;

  a {
    padding: 0.833em;
    display: block;
    line-height: 1;

    &.active {
      font-weight: bold;
    }
  }
}

@include above('tablet') {
  #nav {
    margin-top: -4em;
    .toggle-nav {
      display: none;
    }
  }
}

@include below('tablet') {
  #nav {
    position: relative;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    order: -1;

    color: $dark;

    .toggle-nav {
      flex: 0 0 auto;
      margin: 1.5em;

      transform: scale(1.5);

      cursor: pointer;
    }
  }

  #nav-main {
    position: absolute;
    top: 100%;
    left: 50%;

    width: 12em;
    margin-top: 1em;
    margin-left: -6em;
    padding: 1em 0;

    flex-direction: column;
    align-items: stretch;

    background: rgba(white, 0.7);

    text-align: center;

    &:not(.nav-on) {
      display: none;
    }

    > li {
      > a {
        padding: 0.5em;

        &.active {
          background-color: $light;

          &:after {
            content: none;
          }
        }
      }
    }
  }

  #nav-sub {
    flex: 0 0 100%;
    font-size: 1.25em;

    @include below('phablet') {
      padding-top: 0.5em;
      padding-bottom: 0.5em;

      flex-direction: column;

      text-align: center;

      li {
        > a {
          padding: 0;
          line-height: 1.5;
        }
      }
    }
  }
}
