#root {
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  > * {
    flex: 0 0 auto;
  }

  > #main {
    flex: 1 0 auto;
  }
}
