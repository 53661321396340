@import '../../_styles/imports';

#header {
  padding: 2rem 0 4rem;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  justify-items: center;

  color: white;
  background: $primary url('/images/assets/header/bg.jpg') no-repeat center center;
  background-size: cover;

  text-align: center;

  &.fullscreen {
    min-height: 100vh;
    padding: 8rem 0;
  }

  > * {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  @include below('tablet') {
    padding-bottom: 0;

    &.fullscreen {
      padding: 4rem 0 0;
    }
  }
}

#logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-self: center;

  h1 {
    flex: 0 0 100%;
    margin: 0;
    padding: 0;

    font-size: 10em;
    line-height: 1;
    text-transform: uppercase;
  }

  small {
    flex: 1 0 auto;
    color: $secondary;

    font-family: $headings-font-family;
    font-size: 2em;
    font-weight: bold;
    letter-spacing: 0.25em;
  }

  @include below('tablet') {
    h1 {
      font-size: 5em;

      text-shadow: 0 0 1em rgba(black, 0.1);
    }

    small {
      flex-basis: 100%;
    }
  }
}

#intro {
  h1 {
    text-transform: lowercase;
    font-size: 2.625em;
  }
}

#stamp {
  img {
    width: 5em;
  }
}
