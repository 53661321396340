@import '../../_styles/imports';

.detail {
  position: relative;

  background-color: white;
  border-top: 1px solid $primary;
  border-bottom: 1px solid $primary;

  &__fixed {
    position: absolute;
    left: 0;
    right: 0;

    margin-top: 2em;

    .detail-content > article > .markdown {
      max-height: 4 * 1.5em;
      margin-bottom: 1.5em;

      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  .toggle {
    position: absolute;
    top: 0;
    right: 0;

    width: 1em;
    height: 1em;

    display: flex;
    align-items: center;
    justify-content: center;

    color: $primary;

    font-size: 2em;

    cursor: pointer;

    &:hover {
      color: $secondary;
    }
  }

  .detail-content {
    padding: 2em 0;

    display: flex;

    > figure {
      flex: 0 0 9rem;
      width: 9rem;
      height: 9rem;
      margin: 0;

      overflow: hidden;

      background-color: $secondary;
      border-radius: 4.5rem;

      img {
        max-width: 100%;
        display: block;
      }
    }

    > article {
      flex: 1 0 50%;
      padding: 0 3em;

      display: flex;
      flex-direction: column;

      .markdown {
        flex: 1 0 50%;
      }

      > dl {
        color: $primary;
      }
    }

    > aside {
      align-self: flex-end;
    }

    @include below('lg') {
      flex-wrap: wrap;
      > figure,
      > aside {
        order: -1;
        margin: 0 auto 2em;
      }

      > aside {
        flex-basis: auto;
      }

      > article {
        flex-basis: 100%;
        padding: 0;
      }
    }
  }
}
