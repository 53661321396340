@import '../../_styles/imports';

#template-sponsors {
  padding-bottom: 6em;

  .sponsor-item {
    > figure {
      margin: 0;
      padding: 6em 2em;

      background: linear-gradient($primary, $dark);

      img {
        width: 50%;
        max-width: 16em;
        margin: 0 auto;

        display: block;
      }
    }

    .detail {
      border-width: 0;

      &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        z-index: 50;

        margin-left: -1em;

        border: 1em solid transparent;
        border-bottom-color: white;
        border-top-width: 0 !important;
      }

      article {
        margin: 0 auto;
        max-width: 48em;

        text-align: center;
      }
    }

    &__major {
      > figure {
        img {
          width: 75%;
          max-width: 24em;
        }
      }

      .detail {
        &:before {
          margin-left: -2em;
          border-width: 2em;
        }
      }
    }
  }
}
