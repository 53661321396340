@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-Bold.eot');
  src: url('/fonts/AvenirNext-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/AvenirNext-Bold.woff2') format('woff2'), url('/fonts/AvenirNext-Bold.woff') format('woff'),
    url('/fonts/AvenirNext-Bold.ttf') format('truetype'),
    url('/fonts/AvenirNext-Bold.svg#AvenirNext-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-Italic.eot');
  src: url('/fonts/AvenirNext-Italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/AvenirNext-Italic.woff2') format('woff2'), url('/fonts/AvenirNext-Italic.woff') format('woff'),
    url('/fonts/AvenirNext-Italic.ttf') format('truetype'),
    url('/fonts/AvenirNext-Italic.svg#AvenirNext-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-BoldItalic.eot');
  src: url('/fonts/AvenirNext-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/AvenirNext-BoldItalic.woff2') format('woff2'), url('/fonts/AvenirNext-BoldItalic.woff') format('woff'),
    url('/fonts/AvenirNext-BoldItalic.ttf') format('truetype'),
    url('/fonts/AvenirNext-BoldItalic.svg#AvenirNext-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-Regular.eot');
  src: url('/fonts/AvenirNext-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/AvenirNext-Regular.woff2') format('woff2'), url('/fonts/AvenirNext-Regular.woff') format('woff'),
    url('/fonts/AvenirNext-Regular.ttf') format('truetype'),
    url('/fonts/AvenirNext-Regular.svg#AvenirNext-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Condensed';
  src: url('/fonts/hinted-DINCondensed-Bold.eot');
  src: url('/fonts/hinted-DINCondensed-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/hinted-DINCondensed-Bold.woff2') format('woff2'),
    url('/fonts/hinted-DINCondensed-Bold.woff') format('woff'),
    url('/fonts/hinted-DINCondensed-Bold.ttf') format('truetype'),
    url('/fonts/hinted-DINCondensed-Bold.svg#DINCondensed-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.75em;
}

h4 {
  font-size: 1.2em;
  text-transform: uppercase;
}

h5 {
  font-size: 1em;
  font-weight: bold;
}

main {
  h1,
  h2,
  h3,
  H4,
  h5,
  h6 {
    color: $primary;
  }
}

p,
ul,
ol,
blockquote {
  &:not(:last-child),
  &:not(:last-of-type) {
    margin-bottom: 1em;
  }
  &:last-child,
  &:last-of-type {
    margin-bottom: 0;
  }
}

.lead {
  font-size: 1.25em;
  font-weight: normal;
}

strong,
.strong {
  font-weight: bold;
}

.ttu {
  text-transform: uppercase;
  // font-size: 0.875em;
}

a {
  color: $primary;
  text-decoration: none;

  &:hover,
  a:focus {
    color: $secondary;
    text-decoration: none;
  }
}
