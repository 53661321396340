@import '../../_styles/imports';

.ride-container {
  list-style: none;

  & + & {
    border-top: 1px solid $primary;
  }

  > .detail {
    border: 0;
  }
}

.ride {
  > h3 {
    font-family: $font-family-sans-serif;
  }

  .ride-type {
    display: block;

    font-size: 0.5em;
    font-family: $font-family-sans-serif;
    line-height: 1.25;

    .ride-type-icon {
      position: absolute;
      top: 0em;
      right: 0em;

      color: white;

      font-size: 1.25em;

      pointer-events: none;

      svg {
        position: relative;
        top: 0.5em;
        right: 0.5em;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0em;
        right: 0em;

        border: 1.75em solid $secondary;
        border-left-color: transparent;
        border-bottom-color: transparent;
      }
    }
  }

  .ride-data {
    flex: 0 0 auto;
    padding-right: 1em;

    align-self: center;
    display: flex;
    justify-content: flex-end;

    list-style: none;

    li {
      margin-right: 1em;

      span,
      a {
        width: 2em;
        height: 2em;

        display: flex;
        align-items: center;
        justify-content: center;

        color: white;
        background-color: $primary;
        border-radius: 1em;

        cursor: pointer;

        &:hover,
        &:focus,
        &.active {
          background-color: $secondary;
        }
      }
    }
  }

  > .datalist {
    > span {
      flex: 1 0 100%;
    }
  }

  &:not(.ride__summary) {
    position: relative;

    padding-top: 1em;
    padding-bottom: 1em;

    display: flex;
    align-items: baseline;

    > h3 {
      flex: 0 0 35%;
      max-width: 35%;
      margin-bottom: 0;

      line-height: 1.5;
    }

    > .datalist {
      flex: 0 0 20%;
    }

    > p {
      flex: 1 0 25%;

      line-height: 1.75;
    }

    @include below('netbook') {
      flex-wrap: wrap;

      > h3 {
        flex: 0 0 100%;
        max-width: 100%;

        @include above('phone') {
          padding-right: 6em;
        }

        @include above('sm') {
          small {
            display: inline-block;
            margin-left: 1em;
            vertical-align: baseline;
          }
        }
      }

      > .datalist {
        flex-basis: 30%;
      }

      > p {
        flex-basis: auto;
      }

      @include above('phone') {
        .ride-data {
          position: absolute;
          top: 1.25em;
          right: 1em;
        }
      }
    }

    @include below('phone') {
      > p {
        flex-basis: 100%;

        order: 2;
      }

      .ride-data {
        margin-left: auto;
        padding: 0.5em 0;

        > li {
          margin-right: 0;
          margin-left: 0.5em;
        }
      }

      > .datalist {
        margin-top: 1rem;
        flex: 1 0 50%;
      }

      > p {
        flex-basis: 100%;
        margin-top: 1rem;
      }
    }
  }

  &__summary {
    padding: 2em;

    overflow: hidden;

    color: white;
    background: linear-gradient($primary, $dark);

    .ride-data {
      display: none;
    }

    > h3 {
      position: relative;

      margin: -1.1428571429em -1.1428571429em 1.1428571429em;
      padding: 1em 1.1428571429em;

      color: inherit !important;
      background-color: $dark;
      box-shadow: 0 0.21em 0.28em rgba(black, 0.64);
    }

    .ride-type {
      .ride-type-icon {
        color: $dark;

        font-size: 1.6em;
      }
    }

    > p {
      margin-top: 1em;
      font-size: 1.5em;
    }
  }
}
