@import '../../_styles/imports';

#footer {
  padding: 2rem 0;

  color: white;
  background: $primary url('/images/assets/header/bg.jpg') no-repeat center center;
  background-size: cover;

  text-align: center;

  > * {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}

#partners {
  padding: 4rem 0;

  display: flex;
  justify-content: space-around;
  align-items: center;

  list-style: none;

  @include below('laptop') {
    flex-wrap: wrap;
  }

  li {
    padding: 0 5%;
    flex: 1 0 10%;
    max-width: 25%;
    min-width: 10%;

    @include below('tablet') {
      &:not(.major) {
        flex-basis: 50%;
        max-width: 40%;
        padding: 5%;
      }
    }

    &.major {
      flex-grow: 2;
      max-width: 33.333%;

      @include below('laptop') {
        flex-basis: 100%;
        max-width: 100%;

        img {
          max-width: 15em;
          margin: 0 auto 4em;
        }
      }
    }

    img {
      width: 100%;
      display: block;
    }
  }
}

#info {
  h2 {
    font-size: 1.5em;
    line-height: 1.5;
  }
}
